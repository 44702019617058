import cx from "classnames";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import * as React from "react";
import {ArticleJsonLd} from "gatsby-plugin-next-seo";
import Logo from "../assets/svg/logo.svg";
import TagIcon from "../assets/svg/tag.svg";
import {capitalize} from "../utils/string";
import Seo from "../components/SEO/SEO";
import * as articleStyles from "./amp.module.css";

const articleComponents = {
    paragraph: ({text}) => {
        return <p dangerouslySetInnerHTML={{__html: text}} className={articleStyles.comp__paragraph} />;
    },
    header: ({text, level, idx}) => {
        if (level < 3) {
            return (
                <h2
                    className={articleStyles.comp__header}
                    id={`header-${idx}`}
                    dangerouslySetInnerHTML={{__html: text}}
                />
            );
        }

        return (
            <h3
                className={`${articleStyles.comp__header} ${articleStyles.comp__header_small}`}
                dangerouslySetInnerHTML={{__html: text}}
            />
        );
    },
    list: ({style, items}) => {
        const getItems = () => {
            return (
                <>
                    {items.map((item, idx) => {
                        return (
                            <li key={idx} className={articleStyles.comp__listItem}>
                                <span dangerouslySetInnerHTML={{__html: item}} />
                            </li>
                        );
                    })}
                </>
            );
        };

        return style === "unordered" ? (
            <ul className={articleStyles.comp__list}>{getItems()}</ul>
        ) : (
            <ol className={`${articleStyles.comp__list} ${articleStyles.comp__list_ordered}`}>{getItems()}</ol>
        );
    },
    quote: ({text, caption, note, localFile, photo, type}) => {
        const getMedia = (file) => {
            const fluidFile = file?.childImageSharp?.fluid;

            return (
                <div>
                    {file.svg?.content
                        ? file.svg?.content
                        : !!fluidFile && (
                              <div className={articleStyles.comp__quotePhoto}>
                                  <amp-img
                                      src={fluidFile.srcWebp}
                                      width={fluidFile.presentationWidth}
                                      height={fluidFile.presentationHeight}
                                      layout="responsive"
                                  >
                                      <div fallback>
                                          <amp-img
                                              src={fluidFile.src}
                                              width={fluidFile.presentationWidth}
                                              height={fluidFile.presentationHeight}
                                              layout="responsive"
                                          />
                                      </div>
                                  </amp-img>
                              </div>
                          )}
                </div>
            );
        };

        return (
            <blockquote
                className={cx(articleStyles.comp__quote, {
                    [articleStyles.comp__quote_quotes]: type === "quotes",
                    [articleStyles.comp__quote_background]: type === "background",
                })}
            >
                {text && <p dangerouslySetInnerHTML={{__html: text}} className={articleStyles.comp__quoteText} />}
                <div className={articleStyles.comp__quoteCaption}>
                    {photo && getMedia(localFile, photo)}{" "}
                    <cite>
                        {caption && (
                            <p
                                dangerouslySetInnerHTML={{__html: caption}}
                                className={cx(articleStyles.comp__quoteCite, {
                                    [articleStyles.comp__quoteCite_withImage]: !!photo,
                                })}
                            />
                        )}{" "}
                        {note && (
                            <p dangerouslySetInnerHTML={{__html: note}} className={articleStyles.comp__quoteNote} />
                        )}
                    </cite>
                </div>
            </blockquote>
        );
    },
    image: ({localFile, caption}) => {
        if (!localFile) {
            return null;
        }

        const getMedia = () => {
            const fluidFile = localFile?.childImageSharp?.fluid;

            return localFile.svg?.content
                ? localFile.svg?.content
                : !!fluidFile && (
                      <div className={articleStyles.comp__imageWrapper}>
                          <amp-img
                              src={fluidFile.srcWebp}
                              width={fluidFile.presentationWidth}
                              height={fluidFile.presentationHeight}
                              layout="responsive"
                          >
                              <div fallback>
                                  <amp-img
                                      src={fluidFile.src}
                                      width={fluidFile.presentationWidth}
                                      height={fluidFile.presentationHeight}
                                      layout="responsive"
                                  />
                              </div>
                          </amp-img>
                      </div>
                  );
        };

        return (
            <figure className={articleStyles.comp__image}>
                {getMedia()}
                <figcaption dangerouslySetInnerHTML={{__html: caption}} className={articleStyles.comp__imageCaption} />
            </figure>
        );
    },
    button: ({text, link}) => {
        return (
            <a data-button href={link} className={articleStyles.comp__button}>
                {text}
            </a>
        );
    },
    contents: ({title, links}) => {
        return (
            <div className={articleStyles.comp__contents}>
                <h2 className={articleStyles.comp__contentsTitle}>{title}</h2>
                <ul className={articleStyles.comp__contentsList}>
                    {links.map(({text, anchor}) => {
                        return (
                            <li key={anchor} className={articleStyles.comp__contentsListItem}>
                                <a
                                    href={`#${anchor}`}
                                    className={articleStyles.comp__contentsLink}
                                    dangerouslySetInnerHTML={{__html: text}}
                                    aria-label={`Перейти к заголовку "${text}"`}
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    },
    counters: ({counters}) => {
        return (
            <div>
                {counters.map((row, rowIdx) => {
                    return (
                        <div
                            key={rowIdx}
                            className={`${articleStyles.comp__countersRow} ${
                                articleStyles[`comp__countersRow_${row.length}`]
                            }`}
                        >
                            {row.map(({title, text}, counterIdx) => {
                                return (
                                    <div key={counterIdx}>
                                        <h4 className={articleStyles.comp__countersTitle}>{title}</h4>
                                        <p className={articleStyles.comp__countersText}>{text}</p>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    },
    embed: ({service, width, height, embed}) => {
        if (service === "youtube") {
            const id = embed.split("/").pop();

            return <amp-youtube data-videoid={id} layout="responsive" width={width} height={height} />;
        }

        if (service === "vimeo") {
            const id = embed.split("/").pop().split("?").pop();

            return <amp-vimeo data-videoid={id} layout="responsive" width={width} height={height} />;
        }

        return null;
    },
    table: ({content, withHeadings}) => {
        return (
            <div className={articleStyles.comp__table}>
                {content.map((row, rowIdx) => {
                    const isHeadings = withHeadings && rowIdx === 0;

                    return (
                        <div key={rowIdx} className={articleStyles.comp__tableRow}>
                            {row.map((cellContent, colIdx) => {
                                return (
                                    <div
                                        key={colIdx}
                                        className={`${articleStyles.comp__tableCell} ${
                                            isHeadings ? articleStyles.comp__tableCell_heading : ""
                                        }`}
                                    >
                                        <p dangerouslySetInnerHTML={{__html: cellContent}} />
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    },
    personality: ({description, localFile}) => {
        const fluidFile = localFile?.childImageSharp?.fluid;

        return (
            <div className={articleStyles.comp__personality}>
                {localFile.svg?.content
                    ? localFile.svg?.content
                    : !!fluidFile && (
                          <div className={articleStyles.comp__personalityImage}>
                              <amp-img
                                  src={fluidFile.srcWebp}
                                  width={fluidFile.presentationWidth}
                                  height={fluidFile.presentationHeight}
                                  layout="responsive"
                              >
                                  <div fallback>
                                      <amp-img
                                          src={fluidFile.src}
                                          width={fluidFile.presentationWidth}
                                          height={fluidFile.presentationHeight}
                                          layout="responsive"
                                      />
                                  </div>
                              </amp-img>
                          </div>
                      )}
                <p dangerouslySetInnerHTML={{__html: description}} className={articleStyles.comp__personalityDesc} />
            </div>
        );
    },
};

const Article = ({pageContext: {articleData: article}, location}) => {
    const parsedContent = JSON.parse(article.content);

    let currentHeaderIdx = 0;

    const content = (parsedContent.blocks || []).map((block) => {
        if (block.type !== "header") {
            return block;
        }

        return {
            ...block,
            data: {
                ...block.data,
                idx: block.data.level < 3 ? currentHeaderIdx++ : 0,
            },
        };
    });

    return (
        <div>
            <Seo
                seo={{
                    title: article.title,
                    description: article.description,
                    openGraph: {title: "", description: "", image: [{url: article.featuredImage?.url || ""}]},
                }}
                article
            />
            <ArticleJsonLd
                url={location.href}
                headline={article.title}
                images={[article.featuredImage?.url]}
                datePublished={new Date(article.published_at).toISOString()}
                authorName="ITSM 365"
                publisherName="ITSM 365"
                publisherLogo={article.featuredImage?.url}
                description={article.description}
            />
            <header
                style={{
                    padding: "1rem 0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <a href="/" style={{color: "inherit"}}>
                    <Logo />
                </a>
            </header>
            <main className={articleStyles.amp}>
                <article style={{margin: "0 1rem"}}>
                    {((article.product && article.product.title) ||
                        article.tags.length > 0 ||
                        article.industries.length > 0) && (
                        <div className={articleStyles.amp__tags}>
                            <TagIcon />{" "}
                            {article.product && article.product.title && (
                                <a href={`/blog?products=${article.product.title}`} className={articleStyles.amp__tag}>
                                    {capitalize(article.product.title)}
                                </a>
                            )}{" "}
                            {article.tags.map((tag) => {
                                return (
                                    <a href={`/blog?tags=${tag.slug}`} className={articleStyles.amp__tag} key={tag.id}>
                                        {tag.title}
                                    </a>
                                );
                            })}{" "}
                            {article.industries.map((industry) => {
                                return (
                                    <a
                                        href={`/blog?industries=${industry.slug}`}
                                        className={articleStyles.amp__tag}
                                        key={industry.id}
                                    >
                                        {industry.title}
                                    </a>
                                );
                            })}
                        </div>
                    )}
                    <h1 className={articleStyles.amp__title}>{article.title}</h1>
                    <p className={articleStyles.amp__note}>
                        {article.published_at && (
                            <time dateTime={new Date(article.published_at).toISOString()}>
                                {format(new Date(article.published_at), "d MMMM yyyy", {locale: ru})},
                            </time>
                        )}{" "}
                        <span>{article.readingTime || 0} мин читать</span>
                    </p>
                    {article.authors.length > 0 && (
                        <div className={articleStyles.amp__authors}>
                            {article.authors.map((author) => {
                                const avatar = author.avatar?.localFile?.childImageSharp?.fluid;

                                return (
                                    <div className={articleStyles.amp__author} key={author.id}>
                                        <div className={articleStyles.amp__authorAvatar}>
                                            {avatar && (
                                                <amp-img
                                                    src={avatar.srcWebp}
                                                    width={avatar.presentationWidth}
                                                    height={avatar.presentationHeight}
                                                    layout="responsive"
                                                >
                                                    <div fallback>
                                                        <amp-img
                                                            src={avatar.src}
                                                            width={avatar.presentationWidth}
                                                            height={avatar.presentationHeight}
                                                            layout="responsive"
                                                        />
                                                    </div>
                                                </amp-img>
                                            )}
                                        </div>
                                        <div className={articleStyles.amp__authorName}>
                                            <p className={articleStyles.amp__authorName}>{author.name}</p>
                                            <p className={articleStyles.amp__authorPosition}>{author.position}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div>
                        {content.map(({id, type, data}) => {
                            const Component = articleComponents[type];
                            if (!Component) {
                                return undefined;
                            }
                            return <Component key={id} {...data} />;
                        })}
                    </div>
                </article>
            </main>
        </div>
    );
};

export default Article;
