// extracted by mini-css-extract-plugin
export var amp = "amp-module--amp--po50V";
export var amp__tags = "amp-module--amp__tags--7wbT9";
export var amp__tag = "amp-module--amp__tag--ZFCKa";
export var amp__title = "amp-module--amp__title--zZUiO";
export var amp__note = "amp-module--amp__note--vjPI+";
export var amp__authors = "amp-module--amp__authors--PjCew";
export var amp__author = "amp-module--amp__author--eBtDQ";
export var amp__authorAvatar = "amp-module--amp__author-avatar--z2W3B";
export var amp__authorName = "amp-module--amp__author-name--p7MFU";
export var amp__authorPosition = "amp-module--amp__author-position--liWQd";
export var comp__paragraph = "amp-module--comp__paragraph--ZxCyl";
export var comp__personality = "amp-module--comp__personality--guwvw";
export var comp__header = "amp-module--comp__header--mS88d";
export var comp__header_small = "amp-module--comp__header_small--r7uE6";
export var comp__list = "amp-module--comp__list--rlz2g";
export var comp__list_ordered = "amp-module--comp__list_ordered--QjLMK";
export var comp__listItem = "amp-module--comp__list-item--0Rt7U";
export var comp__quote = "amp-module--comp__quote--M-Ni0";
export var comp__quoteText = "amp-module--comp__quote-text--DbzDV";
export var comp__quoteCite = "amp-module--comp__quote-cite--AyYad";
export var comp__quoteNote = "amp-module--comp__quote-note--e7B4b";
export var comp__quoteCaption = "amp-module--comp__quote-caption--kmayW";
export var comp__quotePhoto = "amp-module--comp__quote-photo--Okj25";
export var comp__quote_quotes = "amp-module--comp__quote_quotes--XYVoF";
export var comp__quote_background = "amp-module--comp__quote_background--91n3q";
export var comp__quoteCite_withImage = "amp-module--comp__quote-cite_withImage--uaAHy";
export var comp__button = "amp-module--comp__button--DsATq";
export var comp__image = "amp-module--comp__image--n5nuE";
export var comp__imageCaption = "amp-module--comp__image-caption--7lpSC";
export var comp__imageWrapper = "amp-module--comp__image-wrapper--2scTw";
export var comp__countersRow = "amp-module--comp__counters-row--1VZVj";
export var comp__countersRow_1 = "amp-module--comp__counters-row_1--c08bm";
export var comp__countersRow_2 = "amp-module--comp__counters-row_2--c55yq";
export var comp__countersRow_3 = "amp-module--comp__counters-row_3--mLUHm";
export var comp__countersRow_4 = "amp-module--comp__counters-row_4--9Wauj";
export var comp__countersTitle = "amp-module--comp__counters-title--aBNVt";
export var comp__countersText = "amp-module--comp__counters-text--F3d3J";
export var comp__table = "amp-module--comp__table--Rbv6N";
export var comp__tableRow = "amp-module--comp__table-row--9I5BF";
export var comp__tableCell = "amp-module--comp__table-cell--IoPE+";
export var comp__tableCell_heading = "amp-module--comp__table-cell_heading--2TI1d";
export var comp__contents = "amp-module--comp__contents--BzEvZ";
export var comp__contentsTitle = "amp-module--comp__contents-title--NZAVy";
export var comp__contentsList = "amp-module--comp__contents-list--OUJW8";
export var comp__contentsListItem = "amp-module--comp__contents-list-item--TVYbH";
export var comp__contentsLink = "amp-module--comp__contents-link--RUy5y";
export var comp__personalityImage = "amp-module--comp__personality-image--nSQ1l";
export var comp__personalityDesc = "amp-module--comp__personality-desc--ollAe";